import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tts_config } from 'src/environments/tts_config';
import { BehaviorSubject } from 'rxjs';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class CommonService {

  encPassword: string="tts@solution";
  decPassword:string="tts@solution";

  private dataWebsetting = new BehaviorSubject([]);
  Websetting = this.dataWebsetting.asObservable();

  private dataSlider = new BehaviorSubject([]);
  HomeSliser = this.dataSlider.asObservable();

  private dataOffer = new BehaviorSubject([]);
  HomeOffer = this.dataOffer.asObservable();

  private dataBlog = new BehaviorSubject([]);
  HomeBlog = this.dataBlog.asObservable();

  private dataFooter = new BehaviorSubject([]);
  FooterMenu = this.dataFooter.asObservable();

  constructor(private http: HttpClient) {

      this.SetWebSiteData();
   }

  public SetWebSiteData() {
    let url=tts_config.APIURL+'/common/websiteinfo';
    return this.http.get(url, {headers: { 'Content-Type': 'application/json'}}).subscribe(response => {
          this.dataWebsetting.next(response['WebsiteInfo']);
          this.dataSlider.next(response['Slider']);
          this.dataOffer.next(response['Offer']);
          this.dataBlog.next(response['Blog']);
          this.dataFooter.next(response['FooterMenu']);
    });
  }

  public GetWebSiteData()
  {
    return this.Websetting;
  }

  public GetHomeSlider()
  {
    return this.HomeSliser;
  }

  public GetHomeOffer()
  {
    return this.HomeOffer;
  }

  public GetHomeBlog()
  {
    return this.HomeBlog;
  }
  public GetFooterMenu()
  {
    return this.FooterMenu;
  }

  public CustomerSignup(data:any)
  {
    let url=tts_config.APIURL+'/customer/login';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }
  public VerifyOTP(data:any)
  {
    let url=tts_config.APIURL+'/customer/verifyotp';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  public ResendOTP(data:any)
  {
    let url=tts_config.APIURL+'/customer/resendotp';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  public CustomerForgotPassword(data:any)
  {
    let url=tts_config.APIURL+'/customer/forgotpassword';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }
  public GenerateNewPassword(data:any)
  {
    let url=tts_config.APIURL+'/customer/changeaccesskey';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }
  public ViewTrip(data:any)
  {
    let url=tts_config.APIURL+'/customer/viewtrip';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }
  public ViewTripContent()
  {
    let url=tts_config.APIURL+'/customer/viewtripcontent';
    return this.http.get(url);
  }

  public Logout()
  {
    let url=tts_config.APIURL+'/customer/logout';
    return this.http.get(url,{headers: { 'Content-Type': 'application/json'}});
  }
  
  PaymentOptions(data:any)
  {
    let url=tts_config.PaymentURL+'/app_payment_options';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  WalletRecharge(data:any)
  {
    let url=tts_config.PaymentURL+'/walletrecharge';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

  ttsEncrypt(text :any)
  {
    return CryptoJS.AES.encrypt(text.trim(), this.encPassword.trim()).toString();
  }

  ttsDecrypt(text :any)
  {
    return CryptoJS.AES.decrypt(text.trim(), this.decPassword.trim()).toString(CryptoJS.enc.Utf8);
  }

  public SaveTravellerList()
  {
    let url=tts_config.APIURL+'/customer/customermemberinfo';
    return this.http.get(url,{headers: { 'Content-Type': 'application/json'}});
  }

  public DeleteTravellerList(data)
  {
    let url=tts_config.APIURL+'/customer/remove_customer_member_info';
    return this.http.post(url, data, {headers: { 'Content-Type': 'application/json'}});
  }

}
