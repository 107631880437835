import { Component } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { AutocloseOverlaysServiceService } from './services/autoclose-overlays-service.service';
import { ScreenOrientation } from '@ionic-native/screen-orientation/ngx';
import { ModalController, Platform } from '@ionic/angular';
import { ScreenRotateComponent } from './modules/modal-popup/screen-rotate/screen-rotate.component';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private router: Router,private autocloseOverlaysService: AutocloseOverlaysServiceService,private so: ScreenOrientation,private platform: Platform,  public modalController: ModalController) {

    // close all popovers on back navigation, if open.
    this.router.events.subscribe((event: any): void => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.autocloseOverlaysService.trigger();
        }
      }
    });

   this.platform.ready().then((source) => { 
      if (this.platform.is('android') && this.platform.is('mobileweb')===false) {
          this.lockToPortrait();
      } else if (this.platform.is('ios') && this.platform.is('mobileweb')===false) {
          this.lockToPortrait();
      }  else {
        var _this = this;
        screen.orientation.addEventListener("change", function(e) {
          if(screen.orientation.type=='portrait-primary')
          {
              _this.closemodel();
          }
          if(screen.orientation.type=="landscape-primary")
          {
            _this.screenrotation();
          }
        }, false);
      }
    }); 
    
  }

  lockToPortrait(){
    this.so.lock(this.so.ORIENTATIONS.PORTRAIT);
  }

  async screenrotation() {
    const modal = await this.modalController.create({
      component: ScreenRotateComponent,
    }); 
    modal.onDidDismiss().then((dataReturned) => {
      if (dataReturned !== null) {
      }
    });
    return await modal.present();
  }

  closemodel() : void {
    this.modalController.dismiss();
  }

}
