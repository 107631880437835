import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { SignUpComponent } from '../modules/modal-popup/sign-up/sign-up.component';
import { AuthenticationService } from '../services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthenticationService,
        public modalController: ModalController
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.authenticationService.currentUserValue;
        if (currentUser) {
            // logged in so return true
            return true;
        }

        // not logged in so redirect to login page with the return url
      //  this.router.navigate(['/']);
        this.loginModal();
        return false;
    } 

    async loginModal() {
        const modal = await this.modalController.create({
          component: SignUpComponent,
        }); 
    
        modal.onDidDismiss().then((dataReturned) => {
          if (dataReturned !== null) {
          }
        });
        return await modal.present();
      }
}
