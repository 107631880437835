import { Component, Input, OnInit } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-save-traveller-list',
  templateUrl: './save-traveller-list.component.html',
  styleUrls: ['./save-traveller-list.component.scss'],
})
export class SaveTravellerListComponent implements OnInit {

  @Input() source: any;
  SaveTravellerList:any=[];
  lodaing=false;
  Message='';
  
  constructor(private commonservice:CommonService,private modalController: ModalController,public alertController: AlertController) { }

  ngOnInit() {
    this.GetSaveTravellerList();
  }

  GetSaveTravellerList()
  {
    this.lodaing=true;
    this.commonservice.SaveTravellerList().subscribe(response => {
      this.lodaing=false;
      if(response['StatusCode']==0)
      {
        this.SaveTravellerList=response['Response'];
      } else {
        this.Message=response['Message'];
      }
    });
  }

  async RemovePax(item:any)
  {
    const alert = await this.alertController.create({
      header: 'Confirm!',
      message: 'Do you want to delete ?',
      buttons: [
        {
          text: 'Disagree',
          role: 'cancel',
          handler: (blah) => {
           
          }
        }, {
          text: 'Agree',
          handler: () => {
            let data={'member_id':item['MemberId']};
            this.commonservice.DeleteTravellerList(data).subscribe(response => {
              if(response['StatusCode']==0)
              {
                  this.GetSaveTravellerList();
              }
            });
          }
        }
      ]
    });
    await alert.present();
  }

  selecttraveller(item:any)
  {
    let role='D';
    this.modalController.dismiss(item,role);
  }

  closemodel(role : any) : void {
    let data:any;
    this.modalController.dismiss(data,role);
  }


}
