import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { tts_config } from '../../environments/tts_config';
import { User } from '../_models';
import { Router } from '@angular/router';



@Injectable({ providedIn: 'root' })
export class AuthenticationService {


    private currentUserSubject: BehaviorSubject<User>;
    public currentUser: Observable<User>;

    constructor(private http: HttpClient, private router: Router) {
        this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue(): User {
        return this.currentUserSubject.value;
    }

    login(emailphone: string, password: string) {
        return this.http.post<any>(`${tts_config.APIURL}/customer/login`, {emailphone, password })
            .pipe(map(user => {
                if(user.StatusCode==0) {
                    localStorage.setItem('currentUser', JSON.stringify(user.Response));
                    this.currentUserSubject.next(user.Response);
                 }
                 return user;
            }));
    }

    setuserdata(Response:any)
    {
        localStorage.setItem('currentUser', JSON.stringify(Response));
        this.currentUserSubject.next(Response);
    }

    sociallogin(data) {
      return this.http.post<any>(`${tts_config.APIURL}/customer/sociallogin`,data)
          .pipe(map(user => {
              if(user.StatusCode==0) {
                  localStorage.setItem('currentUser', JSON.stringify(user.Response));
                  this.currentUserSubject.next(user.Response);
               }
               return user;
          }));
    }

    update(AuthToken:any,data:any)
    {
        if(data)
        {
            if(AuthToken==data['AuthToken']) {
              localStorage.setItem('currentUser', JSON.stringify(data));
              this.currentUserSubject.next(data);
            }
            return data;
        }
    }

    logout() {
        localStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
        this.router.navigate(['/']);
    }
}
