import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModalController, ToastController } from '@ionic/angular';
import { first } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';

import { FacebookLogin, FacebookLoginPlugin } from '@capacitor-community/facebook-login';

import '@codetrix-studio/capacitor-google-auth';
import { Plugins } from '@capacitor/core';


import { isPlatform } from '@ionic/angular';

import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';




@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent implements OnInit {
  
  GetWebSiteData:any=[];

  SinupForm: FormGroup;
  Sinupsubmitted = false;
  Sinuploading = false;
  SinupMessage='';
  SinupErrorMessage='';
  SinupNextStep=false;

  OTPSinupForm: FormGroup;
  OTPSinupsubmitted = false;
  OTPSinuploading = false;
  OTPSinupMessage='';

  ResendOTPloading=false;

  fbLogin: FacebookLoginPlugin;

  token = null;

  FBLoginloading=false;
  GLoginloading=false;
  APLoginloading=false;


  userInfo = null;
  activeResend=false;
  OTPTime=30;

  showAppleSignIn=false;
  
  constructor(private titleService: Title, private commonservice:CommonService,private fb: FormBuilder,private authenticationservice: AuthenticationService,private modalController: ModalController,public toastController: ToastController,private router: Router,private http: HttpClient) {

    this.SinupForm = this.fb.group({
      emailphone: ['',[Validators.required,Validators.pattern(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/)]]
    });

    this.OTPSinupForm = this.fb.group({
      emailphone: ['',[Validators.required,Validators.pattern(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/)]],
      otp: ['',[Validators.required,Validators.maxLength(6)]]
    });

    this.setupFbLogin();
   
   }

  ngOnInit() {
    this.commonservice.GetWebSiteData().subscribe(data => {
      this.GetWebSiteData =data;
      this.titleService.setTitle(this.GetWebSiteData['CompanyName']);
    });

    this.setupAppleLogin();
  }

  get fs() { return this.SinupForm.controls; }

  SignupSubmit()
  {
    this.SinupErrorMessage='';
    this.OTPSinupMessage='';
    this.SinupMessage='';
    this.Sinupsubmitted = true;
    if (this.SinupForm.invalid) {
      return;
    }
    this.Sinuploading=true;
    this.commonservice.CustomerSignup(this.SinupForm.value).subscribe(data => {
      this.Sinuploading=false;
        if(data['StatusCode']===0)
        {
          this.SinupMessage=data['Message'];
          this.SinupNextStep=true;
          this.timer(this.OTPTime);
          
        } else {
          this.SinupErrorMessage=data['Message'];
        }
    });
  }

  ResendOTP()
  {
    this.OTPSinupMessage='';
    this.ResendOTPloading=true;
    this.commonservice.ResendOTP(this.SinupForm.value).subscribe(data => {
      this.ResendOTPloading=false;
      if(data['StatusCode']===0)
      {
        this.OTPSinupMessage='<div class="success-msg">'+data['Message']+'</div>';
      } else {
        this.OTPSinupMessage='<div class="error-msg">'+data['Message']+'</div>';
      }
    });
  }

  get fos() { return this.OTPSinupForm.controls; }

  OTPSinupSubmit()
  {
    this.OTPSinupForm.patchValue({emailphone:this.SinupForm.get('emailphone').value});
    this.OTPSinupMessage='';
    this.OTPSinupsubmitted = true;
    if (this.OTPSinupForm.invalid) {
      return;
    }
    this.OTPSinuploading=true;
    this.commonservice.VerifyOTP(this.OTPSinupForm.value).subscribe(data => {
      this.OTPSinuploading=false;
        if(data['StatusCode']===0)
        { 

          this.authenticationservice.setuserdata(data['Response']);

          this.OTPSinupForm.reset();
          this.OTPSinupsubmitted = false;
          this.closemodel('C');
          this.ToastMessage(data['Message'],3000,'success');
          this.OTPTime=0;
        } else {
          this.ToastMessage(data['Message'],3000,'danger');
        }
    });
  }

  closemodel(role : any) : void {
    let data:any;
    this.modalController.dismiss(data,role);
  }
  async googleLogin() {
    const googleUser = await Plugins.GoogleAuth.signIn(null) as any;
    if(googleUser)
    {
      this.GLoginloading=true;
      googleUser['provider']="GOOGLE";
      this.authenticationservice.sociallogin(googleUser).pipe(first()).subscribe(data => {
        this.GLoginloading=false;
        if(data.StatusCode==0)
        {
          this.closemodel('C');
          this.ToastMessage("You have successfully logged in.",3000,'success');
        } else {
          this.ToastMessage(data.Message,3000,'danger');
        }

      }); 

    } else {
      this.GLoginloading=false;
    }
  }


  async setupFbLogin() {
    if (isPlatform('desktop')) {
      this.fbLogin = FacebookLogin;
    } else {
      // Use the native implementation inside a real app!
      const { FacebookLogin } = Plugins;
      this.fbLogin = FacebookLogin;
    } 
  }

  async Facebooklogin() {
   
    const FACEBOOK_PERMISSIONS = ['email', 'user_birthday'];
    const result = await this.fbLogin.login({ permissions: FACEBOOK_PERMISSIONS });
 
    if (result.accessToken && result.accessToken.userId) {
      this.token = result.accessToken;
      this.loadUserData();
    } else if (result.accessToken && !result.accessToken.userId) {
      // Web only gets the token but not the user ID
      // Directly call get token to retrieve it now
      this.getCurrentToken();
    } else {
      // Login failed
      this.FBLoginloading=false;
    }
  }
 
  async getCurrentToken() {    
    const result = await this.fbLogin.getCurrentAccessToken();
    if (result.accessToken) {
      this.token = result.accessToken;
      this.loadUserData();
    } else {
      // Not logged in.
    }
  }
  async loadUserData() {
    this.FBLoginloading=true;
    const url = `https://graph.facebook.com/${this.token.userId}?fields=id,name,picture.width(720),birthday,email&access_token=${this.token.token}`;
    this.http.get(url).subscribe(res => {
        res['provider']="FACEBOOK";
        this.authenticationservice.sociallogin(res).pipe(first()).subscribe(data => {
          this.FBLoginloading=false;
          if(data.StatusCode==0)
          {
            this.closemodel('C');
            this.ToastMessage("You have successfully logged in.",3000,'success');
          } else {
            this.ToastMessage(data.Message,3000,'danger');
          }

        }); 
        
    });
  }

  async setupAppleLogin() {
    const { Device } = Plugins;
    // Only show the Apple sign in button on iOS
    let device = await Device.getInfo();
    this.showAppleSignIn = device.platform === 'ios';
  }

  appleLogin() {
    const { SignInWithApple } = Plugins;
    SignInWithApple.Authorize()
      .then(async (res) => {
        if (res.response && res.response.identityToken) {

          let Appleresponse=res.response;
          Appleresponse['provider']="APPLE";
          this.authenticationservice.sociallogin(Appleresponse).pipe(first()).subscribe(data => {
            this.APLoginloading=false;
            if(data.StatusCode==0)
            {
              this.closemodel('C');
              this.ToastMessage("You have successfully logged in.",3000,'success');
            } else {
              this.ToastMessage(data.Message,3000,'danger');
            }
  
          }); 

        } else {
         this.APLoginloading=false;
        }
      })
      .catch((response) => {
        this.APLoginloading=false;
      });
  }



  async ToastMessage(msg: any  , time: any , color: string) {
    const toast = await this.toastController.create({
      message: msg,
      duration: time,
      color:color
    });
    toast.present();
  }

  ViewPage(url:any)
  {
    this.closemodel('C');
    this.router.navigate([url]);
  }

  timer(timeleft) {
    var _this = this;
    _this.activeResend=false;
    var downloadTimer = setInterval(function(){
      if(timeleft <= 0){
        clearInterval(downloadTimer);
        document.getElementById("sinuptimer").innerHTML = "";
        _this.activeResend=true;
      } else {
        timeleft=(timeleft < 10 ? '0' : '') + timeleft
        if(timeleft)
        {
          document.getElementById("sinuptimer").innerHTML = "00 :"+timeleft + "";
        }
      }
      timeleft -= 1;
    }, 1000);
    }

}

