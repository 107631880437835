import { NgModule } from '@angular/core';
import { NoPreloading, PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './_helpers';
const routes: Routes = [
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'flight',
    loadChildren: () => import('./modules/flight/flight.module').then( m => m.FlightPageModule)
  },
  {
    path: 'hotel',
    loadChildren: () => import('./modules/hotel/hotel.module').then( m => m.HotelPageModule)
  },
  {
    path: 'offers',
    loadChildren: () => import('./modules/offers/offers.module').then( m => m.OffersPageModule)
  },
  {
    path: 'my-account',
    loadChildren: () => import('./modules/my-account/my-account.module').then( m => m.MyAccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'blog',
    loadChildren: () => import('./modules/blog/blog.module').then( m => m.BlogPageModule)
  },
  {
    path: 'payment',
    loadChildren: () => import('./modules/payment/payment.module').then( m => m.PaymentPageModule)
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error-page/error-page.module').then( m => m.ErrorPagePageModule)
  },
  {
    path: 'payment-response',
    loadChildren: () => import('./modules/payment-response/payment-response.module').then( m => m.PaymentResponsePageModule)
  },
  {
    path: 'holiday',
    loadChildren: () => import('./modules/holiday/holiday.module').then( m => m.HolidayPageModule)
  },
  {
    path: 'bus',
    loadChildren: () => import('./modules/bus/bus.module').then( m => m.BusPageModule)
  },
  {
    path: 'activity',
    loadChildren: () => import('./modules/activity/activity.module').then( m => m.ActivityPageModule)
  },
  {
    path: 'cab',
    loadChildren: () => import('./modules/cab/cab.module').then( m => m.CabPageModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./modules/pages/pages.module').then( m => m.PagesPageModule)
  },
  {
    path: 'bill-payment',
    loadChildren: () => import('./modules/bill-payment/bill-payment.module').then( m => m.BillPaymentPageModule)
  },
  {
    path: 'transfer',
    loadChildren: () => import('./modules/transfer/transfer.module').then( m => m.TransferPageModule)
  },
  {
    path: '**',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then( m => m.PageNotFoundPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { onSameUrlNavigation: 'reload', preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
