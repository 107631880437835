import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Plugins } from '@capacitor/core';

const { Browser } = Plugins;

@Component({
  selector: 'app-web-checkin',
  templateUrl: './web-checkin.page.html',
  styleUrls: ['./web-checkin.page.scss'],
})
export class WebCheckinPage implements OnInit {

  constructor(private modalController: ModalController) { }

  ngOnInit() {
  }

  async externalurl(url:any)
  {
    await Browser.open({ url: url });
  }

  closemodel(role : any) : void {
    let data:any;
    this.modalController.dismiss(data,role);
  }

}
