import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModalController, ToastController } from '@ionic/angular';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit {

  GetWebSiteData:any=[];

  ForgotForm: FormGroup;
  Forgotsubmitted = false;
  Forgotloading = false;
  ForgotMessage='';
  ForgotNextStep:boolean=false;

  NewPasswordForm: FormGroup;
  NewPasswordsubmitted = false;
  NewPasswordloading = false;
  NewPasswordMessage='';

  constructor(private titleService: Title, private commonservice:CommonService,private fb: FormBuilder,private authenticationservice: AuthenticationService,private modalController: ModalController,public toastController: ToastController) {

    this.ForgotForm = this.fb.group({
      emailphone: ['', [Validators.required,Validators.pattern(/^(?:\d{10}|\w+@\w+\.\w{2,3})$/)]]
    });

    this.NewPasswordForm = this.fb.group({
      code: ['', [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword:['',[Validators.required]]
    },{
      validator: this.MustMatch('password', 'confirmPassword')
    });

   }

  ngOnInit() {
    this.commonservice.GetWebSiteData().subscribe(data => {
      this.GetWebSiteData =data;
      this.titleService.setTitle(this.GetWebSiteData['CompanyName']);
    });
  }

  get fps() { return this.ForgotForm.controls; }

  ForgotSubmit()
  {
    this.Forgotsubmitted = true;
    if (this.ForgotForm.invalid) {
      return;
    }
    this.Forgotloading=true;
    this.commonservice.CustomerForgotPassword(this.ForgotForm.value).subscribe(data => {
      this.Forgotloading=false;
      if(data['StatusCode']===0)
      {
        this.ForgotNextStep=true;
        this.ForgotMessage='<div class="success-msg">'+data['Message']+'</div>';
      } else {
        this.ForgotMessage='<div class="error-msg">'+data['Message']+'</div>';
      }
    });

  }

  get fnps() { return this.NewPasswordForm.controls; }

  NewPasswordSubmit()
  {
    this.NewPasswordMessage='';
    this.NewPasswordsubmitted = true;
    if (this.NewPasswordForm.invalid) {
      return;
    }
    this.NewPasswordloading=true;
    this.commonservice.GenerateNewPassword(this.NewPasswordForm.value).subscribe(data => {
      this.NewPasswordloading=false;
      if(data['StatusCode']===0)
      {
        this.NewPasswordForm.reset();
        this.NewPasswordsubmitted = false;
        this.NewPasswordMessage='<div class="success-msg">'+data['Message']+'</div>';
      } else {
        this.NewPasswordMessage='<div class="error-msg">'+data['Message']+'</div>';
      }
    });

  }

  MustMatch(controlName: string, matchingControlName: string) {
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];

        if (matchingControl.errors && !matchingControl.errors.mustMatch) {
            // return if another validator has already found an error on the matchingControl
            return;
        }

        // set error on matchingControl if validation fails
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ mustMatch: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
 }

  closemodel(role : any) : void {
    let data:any;
    this.modalController.dismiss(data,role);
  }

}
