import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { WebCheckinPage } from './web-checkin/web-checkin.page';
import { SignUpComponent } from './sign-up/sign-up.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { SupportComponent } from './support/support.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { NotificationComponent } from './notification/notification.component';
import { ScreenRotateComponent } from './screen-rotate/screen-rotate.component';
import { SaveTravellerListComponent } from './save-traveller-list/save-traveller-list.component';



@NgModule({
  declarations: [WebCheckinPage,SignUpComponent,SupportComponent,ForgotPasswordComponent,NotificationComponent,ScreenRotateComponent,SaveTravellerListComponent],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ReactiveFormsModule,
  ] 
})
export class ModalPopupModule { }
 