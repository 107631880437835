import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Title } from '@angular/platform-browser';
import { ModalController } from '@ionic/angular';
import { CommonService } from 'src/app/services/common.service';

declare var $: any;

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss'],
})
export class SupportComponent implements OnInit {

  GetWebSiteData:any=[];

  ViewTripForm: FormGroup;
  submitted = false;
  loading=false;
  ErrorMessage:string;
  labalinput:string='Ref ID / PNR';

  Content:any=[];

  constructor(private titleService: Title,private modalController: ModalController,private commonservice:CommonService,private fb: FormBuilder) {

    this.ViewTripForm = this.fb.group({
      service:['Flight'],
      refno:['',[Validators.required]],
      dialcode: ['91', [Validators.required]],
      mobilenumber: ['',[Validators.required,Validators.pattern('[0-9]+')]]
    });

   }

  ngOnInit() {

    this.commonservice.GetWebSiteData().subscribe(data => {
      this.GetWebSiteData =data;
      this.titleService.setTitle("24*7 Support -" +this.GetWebSiteData['CompanyName']);
    });

    this.loadcustomjs();
    this.GetContent();
   
  }

  GetContent()
  {
    this.commonservice.ViewTripContent().subscribe(data => {
        this.Content=data;
        setTimeout(() => {
          this.loadcustomaccord();
        }, 50);
    });
  }

  get f() { return this.ViewTripForm.controls; }

  getService(type:string)
  {
      if(type=='Flight')
      { 
        this.labalinput='Ref ID / PNR';
      } else if(type=='Hotel') {
        this.labalinput='Ref ID / Confirmation number';
      }
      this.ViewTripForm.patchValue({service:type});
  }

  Submit()
  {
    this.submitted = true;
    if (this.ViewTripForm.invalid) {
      return;
    }
    this.loading=true;
    this.commonservice.ViewTrip(this.ViewTripForm.value).subscribe(data => {
      this.loading=false;
        if(data['StatusCode']===0)
        {
          
          
        } else {
          this.ErrorMessage=data['Message'];
        }
    });
  }

  closemodel(role : any) : void {
    this.modalController.dismiss({
      'dismissed': true
    });
  }

  loadcustomjs()
  {
    $('.is-floating-label input, .is-floating-label select').on('focus blur', function (e) {
      $(this).parents('.is-floating-label').toggleClass('is-focused', (e.type === 'focus' || this.value.length > 0));
    }).trigger('blur');
  }

  loadcustomaccord()
  {
    $(document).on("click",".filter-accordion", function(e) {
        e.preventDefault();
        $(this).next().slideToggle(200);
        $(this).toggleClass('is-open', 200);
    });
  }


}
